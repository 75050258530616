import finmutualConf from "../services/reducers/configReducer/config/finmutualConf";
import {
  isDemoFlavour,
  isLocalhost,
  isStaging,
} from "../services/reducers/configReducer/config/rootConf";
import { FINMUTUAL, MAYEUTIC, TEMPLATE } from "./theme";

let theme;
if (isLocalhost() || (isStaging() && isDemoFlavour())) {
  // theme = FINMUTUAL;
  theme = MAYEUTIC;
}
else if (isStaging()) theme = MAYEUTIC; 
else if (finmutualConf.is()) theme = FINMUTUAL;
else theme = TEMPLATE;

export { theme };
